import {
  MediaPlatformImage,
  PrivacyStatus,
  RoleId,
} from '@wix/members-domain-ts';

import {
  Handler,
  HTTPMethod,
  PublicMember,
  RolesMap,
  InjectedSite,
} from '../types';
import { ViewMode } from '../types/controller';
import request, { requestText } from './request';

type InstalledAppsMap = InjectedSite['installedApps'];

type SocialChatResponse = { isSocialChat: boolean };

type UpdatableFields = Pick<
  PublicMember,
  'name' | 'title' | 'picture' | 'cover'
>;

export type PartialUpdatableFields = Partial<UpdatableFields>;

interface ApplyRoleActionOptions {
  uid: string;
  roles: RoleId[];
  roleId: RoleId;
  viewMode: ViewMode;
}

interface MediaCredentialsResponse {
  uploadToken: string;
  uploadUrl: string;
}

class MembersService {
  constructor(
    private readonly baseUrl: string,
    private readonly getInstance: Handler<string>,
  ) {}

  public readonly getMember = (uid: string) =>
    request<PublicMember>(`${this.baseUrl}/members/${uid}`, {
      method: HTTPMethod.Get,
      headers: this.getHeaders(),
    });

  public readonly partialMemberUpdate = (
    uid: string,
    updatedFields: PartialUpdatableFields,
  ) =>
    request<PublicMember>(`${this.baseUrl}/members/${uid}`, {
      method: HTTPMethod.Patch,
      headers: this.getHeaders(),
      body: JSON.stringify(updatedFields),
    });

  public readonly setCurrentMemberPrivacyStatus = (
    privacyStatus: PrivacyStatus,
  ) =>
    requestText(`${this.baseUrl}/members/me/privacy-status`, {
      method: HTTPMethod.Put,
      headers: this.getHeaders(),
      body: JSON.stringify({ privacyStatus }),
    });

  public readonly setMemberBadges = (uid: string, badgeIds: string[]) =>
    requestText(`${this.baseUrl}/members/${uid}/badges`, {
      method: HTTPMethod.Post,
      headers: this.getHeaders(),
      body: JSON.stringify({ badgeIds }),
    });

  public readonly deleteMember = (uid: string) =>
    requestText(`${this.baseUrl}/members/${uid}`, {
      method: HTTPMethod.Delete,
      headers: this.getHeaders(),
    });

  public readonly applyRoleAction = ({
    uid,
    roleId,
    roles,
    viewMode,
  }: ApplyRoleActionOptions) => {
    const viewModeLowerCase = viewMode.toLowerCase();
    const url = `${this.baseUrl}/members/${uid}/roles/${roleId}?viewMode=${viewModeLowerCase}`;
    const method = roles.includes(roleId) ? HTTPMethod.Delete : HTTPMethod.Post;

    return requestText(url, { method, headers: this.getHeaders() });
  };

  public readonly getRolesMap = () =>
    request<RolesMap>(`${this.baseUrl}/members/rolesmap`, {
      method: HTTPMethod.Get,
      headers: this.getHeaders(),
    });

  public readonly getInstalledApps = () => {
    const url = `${this.baseUrl}/site/installed-apps`;

    return request<InstalledAppsMap>(url, {
      method: HTTPMethod.Get,
      headers: this.getHeaders(),
    });
  };

  public readonly getIsSocialChat = async () => {
    const url = `${this.baseUrl}/site/social-chat`;
    const { isSocialChat } = await request<SocialChatResponse>(url, {
      method: HTTPMethod.Get,
      headers: this.getHeaders(),
    });

    return isSocialChat;
  };

  public readonly toggleMemberFollowStatus = (
    uid: string,
    isSubscribed: boolean,
  ) => {
    const suffix = isSubscribed ? 'unfollow' : 'follow';
    const url = `${this.baseUrl}/members/${uid}/${suffix}`;

    return requestText(url, {
      method: HTTPMethod.Post,
      headers: this.getHeaders(),
    });
  };

  public readonly getMediaPlatformCredentials = () => {
    const url = `${this.baseUrl}/media/credentials/picture`;
    return request<MediaCredentialsResponse>(url, {
      method: HTTPMethod.Get,
      headers: this.getHeaders(),
    });
  };

  public readonly setMediaPlatformPictureDetails = (
    picture: MediaPlatformImage,
  ) =>
    requestText(`${this.baseUrl}/media/index`, {
      method: HTTPMethod.Post,
      headers: this.getHeaders(),
      body: JSON.stringify(picture),
    });

  private readonly getHeaders = () => ({
    'Content-Type': 'application/json',
    instance: this.getInstance(),
  });
}

export default MembersService;
