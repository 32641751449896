import { shareUrlModal } from '../../constants/modal-type';
import { AdditionalActionId, Thunk, ThunkWithArgs } from '../../types';
import { openModalWithCallback } from '../../services/modal';
import { togglePublicProfilePreview } from './public-profile-preview';

type AdditionalActionsMap = {
  [key in AdditionalActionId]: Thunk;
};

const shareProfile: Thunk = () => async (
  _,
  __,
  { compId, platformAPIs, wixCodeApi },
) => {
  const payload = { url: wixCodeApi.location.url };
  const onConfirm = () => {};

  openModalWithCallback({
    compId,
    modalType: shareUrlModal,
    payload,
    platformAPIs,
    wixCodeApi,
    onConfirm,
  });
};

const actionsMap: AdditionalActionsMap = {
  [AdditionalActionId.ShareProfile]: shareProfile,
  [AdditionalActionId.ViewPublicProfile]: togglePublicProfilePreview,
};

export const executeAdditionalAction: ThunkWithArgs<AdditionalActionId> = (
  actionId,
) => async (dispatch, getState, extra) => {
  const actionHandler = actionsMap[actionId];
  if (!actionHandler) {
    return;
  }
  await actionHandler()(dispatch, getState, extra);
};
